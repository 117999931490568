<template>
  <div class="kegiatan">
    <Navbar />
    <Welcome
      :deskripsi="layout.deskripsi"
      :nama_sekolah="layout.nama_sekolah"
    />

    <div class="container">
      <div class="row py-3">
        <div class="col-md-12 mb-4">
          <h2 class="header"><i class="bi-newspaper"></i> Semua Kegiatan</h2>
          <div class="row mt-4">
            <div
              class="col-md-6 col-lg-4 mb-3"
              v-for="activity in activities"
              :key="activity.id"
            >
              <CardKegiatan
                :title="activity.title"
                :thumbnail="activity.thumbnail"
                :published="activity.published"
                :slug="activity.slug"
              ></CardKegiatan>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-6 col-lg-4">
          <h2 class="header"><i class="bi-tags"></i> Tags</h2>
          <div class="row mt-4">
            <div class="col mb-3">
              <ButtonTags />
              <ButtonTags />
              <ButtonTags />
              <ButtonTags />
              <ButtonTags />
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <Footer :tentang="layout.tentang_sekolah" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Kegiatan",
  components: {
    Navbar: () => import("@/components/Navbar.vue"),
    Welcome: () => import("@/components/Welcome.vue"),
    CardKegiatan: () => import("@/components/CardKegiatan.vue"),
    // ButtonTags: () => import("@/components/ButtonTags.vue"),
    Footer: () => import("@/components/Footer.vue"),
  },
  data() {
    return {
      activities: [],
      layout: [],
    };
  },

  mounted() {
    this.getLayout();
    this.getActivity();
  },

  methods: {
    async getActivity() {
      let response = await axios.get("activity");
      this.activities = response.data.data;
    },
    async getLayout() {
      let response = await axios.get("layout");
      this.layout = response.data.data;
    },
  },
};
</script>
